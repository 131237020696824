.section5-follow-us {
  .bandeau_container {
    width: 100vw;
    overflow: hidden;
    white-space: nowrap;
    padding-bottom: 100px;

    @include break(tablet) {
      padding-bottom: $pad-xlarge_mobile;
    }
  }

  .bandeau {
    display: inline-flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-around;
    align-items: center;
    transform-style: preserve-3d;
    font-size: 110px;
    @media only screen and (min-width: 1600px) {
      font-size: 125px;
    }
    @media only screen and (min-width: 1800px) {
      font-size: 135px;
    }
    @include break(small-screen) {
      font-size: 15vw;
    }

    .txt {
      font-weight: 600;
      padding-right: $pad-large;
      line-height: 1em;
      text-transform: uppercase;

      @include break(tablet) {
        padding-right: $pad-large / 2;
      }

      &.outline {
        text-stroke: 1px $white;
        -webkit-text-stroke: 1px $white;
        color: transparent;
      }
    }
  }

  ul.social-media {
    display: flex;
    justify-content: center;
    gap: $pad-large;

    .social-item {
      &:hover {
        opacity: 0.85;
      }
      @include break(tablet) {
        svg {
          max-width: 40px;
        }
      }
    }
  }
}