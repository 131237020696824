.section3-videos {
  padding-bottom: $pad-xlarge;
  @include break(tablet) {
    padding-bottom: $pad-xlarge_mobile;
  }

  .grid {
    display: flex;
    gap: 80px;
    max-height: 38rem;
    @include break(tablet) {
      gap: 0;
      flex-direction: column;
      max-height: 55rem;
    }
  }

  .wrapper-img {
    position: relative;

    img {
      border-radius: 20px;
    }

    .circle {
      position: absolute;
      top: 10px;
      right: 10px;
      width: 13px;
      height: 13px;
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: $secondary;
      border-radius: 50px;
      @include break(tablet) {
        width: 8px;
        height: 8px;
      }
    }
  }

  .main-video {
    min-width: 50%;
    max-width: 60%;
    @include break(tablet) {
      max-width: 100%;
    }

    p.btn {
      display: inline-flex;
      align-items: center;
      padding: 10px 35px;
      border-radius: 100px;
      background-color: $white;
      color: $primary;
      line-height: 100%;
      text-align: center;
      font-size: 25px;
      font-weight: 600;
      @include break(tablet) {
        font-size: 1rem;
        padding: 8px 15px;
      }
    }

    &:hover {
      p.btn {
        opacity: 0.85;
      }
    }

    .wrapper-img {
      img {
        margin-bottom: $pad-small;
        border: 2px solid $white;
      }

      .circle {
        width: 52px;
        height: 52px;

        @include break(tablet) {
          width: 27px;
          height: 27px;
          svg {
            max-width: 9px;
            max-height: 11px;
          }
        }
      }
    }
  }

  .description {
    padding-bottom: 40px;
    
    @include break(tablet) {
      padding-bottom: 30px;
    }
  }

  a.btn {
    @include break(tablet) {
      margin-bottom: 20px;
    }
  }
}

.wrapper-list-video {
  overflow-y: scroll;

  @include break(tablet) {
    overflow-y: initial;
  }

  &::-webkit-scrollbar {
    width: 5px;
    background-color: $primary;

    @include break(tablet) {
      width: 0;
    }
  }

  &::-webkit-scrollbar-thumb {
    height: 8px;
    background: #fff;
    border-radius: 8px;
  }

  h3.title-2 {
    display: none;
    @include break(tablet) {
      display: flex;
    }
  }

  ul.list-video {
    @include break(tablet) {
      padding: 15px;
      border-radius: 10px;
      background-color: $primary-dark;
      overflow-y: scroll;
      height: 600px;
    }

    @include break(mobile) {
      height: 350px;
    }

    .thumbnail-video {
      padding-bottom: $pad-xsmall;

      a {
        display: flex;
        gap: 32px;
      }

      .wrapper-img {
        img {
          height: auto;
          max-width: 170px;
          border-radius: 5px;
          border: 1px solid $white;
        }

        .circle {
          svg {
            max-width: 4.5px;
            max-height: 5px;
            @include break(tablet) {
              max-width: 2px;
              max-height: 3px;
            }
          }
        }
      }

      .wrapper {
        padding-right: 10px;
        @include break(tablet) {
          padding-right: 0;
        }

        h3 {
          font-size: 16px;

          @include break(tablet) {
            font-size: 14px;
            padding-bottom: 0;
          }
        }

        .description {
          font-size: 12px;
          padding-bottom: initial;
          display: -webkit-box;
          -webkit-line-clamp: 3;
          -webkit-box-orient: vertical;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }
    }
  }
}

