@import 'partials/_mixin';
@import 'partials/_function';

@import "./base/variable";
@import './base/breakpoint';
@import "./base/reset.scss";
@import './base/normalize';
@import './base/easing';
@import './base/fonts';

@import './common/header';
@import './common/footer';
@import "./common/general";
@import "./common/anim-class";

@import 'sections/section-1';
@import 'sections/section-2';
@import 'sections/section-3';
@import 'sections/section-4';
@import 'sections/section-5';
@import 'sections/section-6';