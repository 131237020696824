header {
  .container {
    display: flex;
    align-items: center;
    padding: 40px 0;
  }

  .logo {
    height: auto;
    flex: 1;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;

    svg {
      width: 100%;
      max-width: 270px;
      max-height: 77px;
      @include break(tablet) {
        max-width: 136px;
        max-height: 40px;
      }
    }
  }

  .language-switcher {
    a {
      @include font-medium();
      @include break(tablet) {
        font-size: 12px;
      }
    }
  }

}
