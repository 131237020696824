.section2-slider {
  padding-bottom: $pad-xlarge;
  @include break(tablet) {
    padding-bottom: $pad-xlarge_mobile;
  }

  h2 {
    text-align: center;
  }

  &_slides {
    width: 100%;
    height: 40rem;
    // overflow: hidden;
    position: relative;
    @include break(tablet) {
      overflow: initial;
      height: 80dvh;
      min-height: 680px;
    }

    .arrow {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      width: 36px;
      z-index: 3;
      cursor: pointer;

      &_left {
        left: 3%;
      }

      &_right {
        right: 3%;
      }
    }

    .slide {
      width: 80%;
      background-color: $primary-dark;
      border-radius: 20px;
      position: absolute;
      top: 0;
      left: 50%;
      height: 40rem;
      transform: translateX(150%);
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      grid-gap: 5rem;
      align-items: center;
      justify-content: center;
      padding: 2.5rem;

      @include break(tablet) {
        grid-template-columns: 1fr;
        align-items: start;
        padding: 0.9rem 0.9rem 2.5rem;
        height: 100%;
        min-height: 680px;
        grid-gap: 0;
      }

      &:first-of-type {
        transform: translateX(-50%);
      }

      &:nth-of-type(2) {
        transform: translateX(65%);
      }

      &:last-of-type {
        transform: translateX(-165%);
      }

      .left {
        text-align: center;
        @include break(tablet) {
          height: 15rem;
          max-height: 15rem;
          overflow: hidden;
          z-index: 1;
          margin-bottom: $pad-small;
        }

        &_image {
          width: 100%;
          height: auto;
          max-width: 30rem;
          object-fit: cover;
          @include img-black-and-white();
        }
      }

      .right {
        &_spotlight {
          text-transform: uppercase;
          font-size: 2.1rem;
          font-weight: 500;
          letter-spacing: 1.75px;
          line-height: 115%;
          padding-bottom: 0.6rem;
          @include break(tablet) {
            font-size: 1.375rem;
          }
        }

        &_name {
          font-size: 1.8rem;
          line-height: 115%;
          @include break(tablet) {
            font-size: 1.25rem;
          }
        }

        &_social {
          display: block;
          color: $secondary;
          font-size: 1.125rem;
          padding-bottom: $pad-small;
          z-index: 10;
          position: relative;
          @include break(tablet) {
            font-size: 14px;
            padding-bottom: $pad-xsmall;
          }
        }

        &_description {
          line-height: 115%;
          padding-bottom: 2.5rem;
          @include break(tablet) {
            line-height: 130%;
            font-size: 17px;
            padding-bottom: $pad-small;
          }
        }
      }

      .absolute {
        position: absolute;
        top: 0;
        right: 0;
        height: auto;
        width: 100%;
        @include break(tablet) {
          width: 50%;
        }

        &_image {
          position: absolute;
          top: 0;
          right: 0;
        }
      }

    }
  }
}
