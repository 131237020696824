.section4-references {
  padding-bottom: $pad-xlarge;

  @include break(tablet) {
    padding-bottom: $pad-xlarge_mobile;
  }

  .list {
    .list-item {
      &:not(:last-child) {
        padding-bottom: $pad-xsmall;
      }

      &:hover {
        .absolute {
          opacity: 1;

          img {
            transform: translateY(0%);
          }
        }

        .wrapper .arrow-wrapper {
          .arrow-btn {
            &.first {
              opacity: 0;
              transform: translateY(-200%);
            }

            &.last {
              opacity: 1;
              transform: translateY(0) rotate(45deg);
            }
          }
        }
      }

      @include break(tablet) {
        padding-bottom: 0;
      }

      a {
        padding-top: $pad-large;
        display: flex;
        justify-content: space-between;
        position: relative;
        border-bottom: 1px solid $white;

        @include break(tablet) {
          padding-top: $pad-med_mobile;
        }

        .wrapper {
          flex: 1;
          display: flex;
          justify-content: space-between;
          align-items: center;
          padding-bottom: $pad-small;

          @include break(tablet) {
            padding-bottom: 1rem;
          }

          .text-wrapper {
            h3 {
              font-size: 1rem;
              letter-spacing: 2.2px;
              text-transform: uppercase;
              padding-bottom: 5px;
              @include break(tablet) {
                letter-spacing: 0.8px;
                max-width: 70%;
                padding-bottom: 0;
              }
            }

            p {
              color: $secondary;
              font-size: 16px;
              font-weight: 400;
              @include break(tablet) {
                display: none;
              }
            }
          }

          .arrow-wrapper {
            position: relative;
            width: 19px;
            height: 33px;

            .arrow-btn {
              position: absolute;
              top: 0;
              left: 0;
              width: 100%;
              transition: opacity 0.3s, transform 0.3s ease-out;

              &.first {
                opacity: 1;
                transform: translateY(0);
                z-index: 2;
              }

              &.last {
                opacity: 0;
                transform: translateY(100%) rotate(0deg);
                z-index: 1;
              }
            }
          }
        }

        .absolute {
          position: absolute;
          bottom: -$pad-small;
          left: 60%;
          opacity: 0;
          overflow: hidden;
          transform: translateX(-50%);
          transition: opacity 0.3s;

          img {
            transform: translateY(100%);
            transition: transform 0.3s ease-out;
          }
        }
      }
    }
  }
}