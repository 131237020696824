/*  --- Variable ---  */
$font-size: 22px;
$font-size-small: 16px;
$header-h: 100px;
$header-h_mobile: 60px;
$container: 1325px;

/*  --- Padding ---  */
 $pad-xxsmall: 8px;
 $pad-xsmall: 20px;
 $pad-small: 32px;
 $pad-med: 48px;
 $pad-large: 60px;
 $pad-xlarge: 140px;

 $pad-med_mobile: 32px;
 $pad-large_mobile: 40px;
 $pad-xlarge_mobile: 60px;

//COLOR
$dark: #000000;
$white: #FFFFFF;

$primary: #1C2645;
$primary-dark: #131B33;
$secondary: #4FA3F0;