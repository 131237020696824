.section1-hero {
  &_image {
    width: 100%;
    background-repeat: no-repeat;
    background-size: cover;
    margin-bottom: $header-h;
    @include break(tablet) {
      margin-bottom: $header-h_mobile;
    }

    &-desktop {
      height: 28.75rem;
      max-height: 460px;
      background-position: left;
      @include break(tablet) {
        display: none;
      }
    }

    &-mobile {
      height: 11.25rem;
      background-position: center;
      @include break(desktop-only) {
        display: none;
      }
    }
  }

  &_content {
    text-align: center;

    h1 {
      margin: 0 0 $pad-small;
      @include break(tablet) {
        margin: 0 0 1rem;
      }
    }

    .introduction {
      margin-bottom: $pad-med;
      @include break(tablet) {
        font-size: 20px;
        margin-bottom: 40px;
      }
    }
  }
}