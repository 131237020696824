html {
  overflow-x: hidden;
  overflow-y: scroll;
  background-color: $white;
  color: $dark;
  line-height: normal;

  &.overflow {
    overflow: hidden;
  }

  &::-webkit-scrollbar {
    width: 0 !important;
  }

  overflow: -moz-scrollbars-none;
  -ms-overflow-style: none;
  min-height: -webkit-fill-available;
}

body {
  &.overflow {
    overflow: hidden;
  }

  background-color: $primary;
  color: $white;
  font-size: $font-size;
  @include break(mobile) {
    font-size: $font-size-small;
    overflow-x: hidden;
    overflow-y: scroll;
  }
  @include font-regular;
  font-style: normal;
  min-height: 100vh;
  min-height: -webkit-fill-available;
}

.container {
  width: 90%;
  margin-left: auto;
  margin-right: auto;
  max-width: $container;
}

.flex {
  display: flex;
  justify-content: space-between;

  > * {
    width: 50%;
  }
}

.mobile {
  display: none;
  @include break(mobile) {
    display: block;
  }
}

.desktop {
  @include break(mobile) {
    display: none;
  }
}

.hideMobile {
  @include break(tablet) {
    display: none;
  }
}

.hideDesktop {
  @include break(desktop-only) {
    display: none;
  }
}

a {
  color: inherit;
  text-decoration: none;
  transition: all 0.3s;
}

h1, h2, h3, h4 {
  a {
    color: inherit;
    text-decoration: none;
  }
}

h1, .title-h1 {
  @include font-medium;
  font-size: 45px;
  letter-spacing: 4.5px;
  text-transform: uppercase;
  @include break(tablet) {
    font-size: 1.875em;
    letter-spacing: 2px;
    line-height: normal;
  }
}

h2, .title-h2 {
  @include font-medium;
  font-size: 40px;
  margin-bottom: $pad-large;
  @include break(tablet) {
    font-size: 26px;
    margin-bottom: $pad-large-mobile;
  }
}

h3, .title-h3 {
  @include font-medium;
  font-size: 1.875rem;
  padding-bottom: 12px;
  @include break(tablet) {
    font-size: 1.25em;
  }
}

// Color
.secondary-color {
  color: $secondary;
}

// Boutons / Link
a.btn {
  display: inline-flex;
  align-items: center;
  padding: 10px 35px;
  border-radius: 100px;
  background-color: $white;
  color: $primary;
  line-height: 100%;
  text-align: center;
  font-size: 25px;
  font-weight: 500;
  @include break(tablet) {
    font-size: 1rem;
    padding: 8px 15px;
  }

  &:hover {
    opacity: 0.85;
  }
}

// Separator
.separator {
  background-color: $white;
  width: 100%;
  max-width: 700px;
  height: 2px;
  margin: $pad-xlarge auto;
  @include break(tablet) {
    margin: $pad-large auto;
  }
}


// Titre h2 - section
.title_top {
  padding-right: 40%;
  padding-bottom: $pad-large;
  @include break(tablet) {
    padding-right: 0%;
    padding-bottom: $pad-large_mobile;
  }

  .txt {
    padding-top: $pad-xsmall;
    @include break(tablet) {
      padding-bottom: $pad-small;
    }
  }
}